<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="finishFunction">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";
import {httpQuestionnaire} from "@/services";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import Executors from "../../../components/common/questionnaires/fieldsets/will/Executors";
import Trustees from "../../../components/common/questionnaires/fieldsets/will/Trustees";
import Pets from "../../../components/common/questionnaires/fieldsets/will/Pets";
import GuardiansWithChildren from "../../../components/common/questionnaires/fieldsets/will/GuardiansWithChildren";
import Funeral from "../../../components/common/questionnaires/fieldsets/will/Funeral";
import Gifts from "../../../components/common/questionnaires/fieldsets/will/Gifts";
import DistributionPrimary from "../../../components/common/questionnaires/fieldsets/will/DistributionPrimary";
import DistributionSecondary from "@/components/common/questionnaires/fieldsets/will/DistributionSecondary";
import DistributionBackup from "@/components/common/questionnaires/fieldsets/will/DistributionBackup";

export default {
  name: "EstatePlanningWill",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    Executors,
    Trustees,
    GuardiansWithChildren,
    Pets,
    Funeral,
    Gifts,
    DistributionPrimary,
    DistributionSecondary,
    DistributionBackup
  },
  data() {
    return {
      name: "EstatePlanningWill",
      title: "Will Instructions",
      url: "estate_planning_will",
      order: [
        "Executors",
        "Trustees",
        "GuardiansWithChildren",
        "DistributionPrimary",
        'DistributionSecondary',
        'DistributionBackup',
        "Gifts",
        "Pets",
        "Funeral",
      ],
      finishFunction: this.instructionsTaken,
      expiryAccessCodeOnFinish: true

    };
  },
  methods: {
    async instructionsTaken() {
      return httpQuestionnaire
          .patch(this.url, {
            will_id: this.form.id,
            status: "awaiting_checking",
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
};
</script>
