<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Guardians</h3>

    <ChildrenYN v-model="form.children_have"
                :children="form.children"
                :number-of-children="form.profileChildrenNumber"
                @numberOfChildren="form.profileChildrenNumber=$event" />

    <div v-if="form.children_have"
         class="question-box with-arrow">
      <ChildrenShort ref="childrenFull"
                     v-model="form.children"
                     :number-of-children="form.profileChildrenNumber"
                     @numberOfChildren="form.profileChildrenNumber=$event"
                     @refresh="refresh"
                     @refreshDelete="refresh"
                     @refreshPatch="refresh" />
    </div>

    <template v-if="childrenYoung.length">
      <SeparateGuardians v-model="form.data.guardians.details.separateGuardians"
                         :callbackFunction="enable"
                         :childrenData="childrenYoung" />

      <template v-if="details.separateGuardians">

        <IndividualGuardians v-model="form.data.guardians.details.individual"
                             :callbackFunction="assignChildren" />

        <template v-if="details.individual === false">

          <div class="question-box with-arrow">
            <PrimaryGuardians v-model="form.data.guardians.details.primary"
                              :details="form.data.guardians.details"
                              :optionsData="primaryOptions" />
          </div>

          <SecondaryYN v-if="details.primary.length || details.secondary.length"
                       v-model="form.data.guardians.details.secondaryYN"
                       :callbackFunction="clearSecondary"
                       :details="form.data.guardians.details" />

          <div v-if="secondaryRequired && (details.primary.length || details.secondary.length)"
               class="question-box with-arrow">
            <SecondaryGuardians v-model="form.data.guardians.details.secondary"
                                :details="form.data.guardians.details"
                                :optionsData="secondaryOptions" />
          </div>




        </template>

        <template v-else-if="details.individual">

          <div v-for="(child, index) in form.data.guardians.details.children"
               :key="'childGuardians' + index" class="mt-48">
            <div class="question-box-intro-two">
         <h3>Guardianship for {{ child.name_first }}</h3>
            </div>
            <div v-if="childOptions[index]">
              <PrimaryChildGuardians v-model="form.data.guardians.details.children[index].guardians.primary"
                                     :child="child"
                                     :index="index"
                                     :options-data="childOptions[index].primaryOptions" />
            </div>

            <ChildSecondaryYN v-if="details.children[index].guardians.primary.length"
                              v-model="form.data.guardians.details.children[index].guardians.secondaryYN"
                              :child="child"
                              :index="index" />

            <div v-if="childOptions[index] && details.children[index].guardians.secondaryYN"
                 class="question-box with-arrow">
              <SecondaryChildGuardians v-model="form.data.guardians.details.children[index].guardians.secondary"
                                       :child="child"
                                       :index="index"
                                       :options-data="childOptions[index].secondaryOptions" />
            </div>

            <ChildBackupYN v-if="details.children[index].guardians.secondary.length || details.children[index].guardians.backup.length"
                           v-model="form.data.guardians.details.children[index].guardians.backupYN"
                           :child="child"
                           :index="index" />

            <div v-if="childOptions[index] && details.children[index].guardians.backupYN"
                 class="question-box with-arrow">

              <BackupChildGuardians v-model="form.data.guardians.details.children[index].guardians.backup"
                                    :child="child"
                                    :index="index"
                                    :options-data="childOptions[index].backupOptions" />
            </div>

          </div>

        </template>

        <ChildrenDirection v-if="[true, false].includes(details.individual)"
                           v-model="form.data.guardians.details.direction" />
      </template>

    </template>


  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {willGuardiansHelpers} from "./helpers/willGuardiansHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {personHelpers} from "@/mixins/personHelpers";

import PrimaryGuardians from "../../question/questions/will/guardians/PrimaryGuardians";
import SecondaryGuardians from "../../question/questions/will/guardians/SececondaryGuardians";
import SecondaryYN from "../../question/questions/will/guardians/SecondaryYN";
import SeparateGuardians from "../../question/questions/will/guardians/SeparateGuardians";
import ChildrenYN from "../../question/questions/clientProfile/people/ChildrenYN";
import ChildrenDirection from "../../question/questions/will/guardians/ChildrenDirections";
import IndividualGuardians from "../../question/questions/will/guardians/IndividualGuardians";
import PrimaryChildGuardians from "../../question/questions/will/guardians/children/PrimaryChildGuardians";
import SecondaryChildGuardians from "../../question/questions/will/guardians/children/SecondaryChildGuardians";
import ChildSecondaryYN from "../../question/questions/will/guardians/children/ChildSecondaryYN";
import ChildBackupYN from "../../question/questions/will/guardians/children/ChildBackupYN";
import BackupChildGuardians from "../../question/questions/will/guardians/children/BackupChildGuardians";
import ChildrenShort from "../../question/questions/clientProfile/people/ChildrenShort";

export default {
  name: 'GuardiansWithChildren',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    personHelpers,
    willGuardiansHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    ChildrenShort,
    BackupChildGuardians,
    ChildBackupYN,
    ChildSecondaryYN,
    SecondaryChildGuardians,
    PrimaryChildGuardians,
    IndividualGuardians,
    ChildrenDirection,
    ChildrenYN,
    SeparateGuardians,
    SecondaryYN,
    SecondaryGuardians,
    PrimaryGuardians
  },
  data() {
    return {
      label: 'Guardians', // used for steps progress
      primaryOptions: [],
      secondaryOptions: [],
      db: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.guardians.details'
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    details() {
      return this.form.data.guardians.details
    },
    storePeople() {
      return this.$store.getters.people
    }
  },
  methods: {
    async refresh() {
      if ('childrenFull' in this.$refs) {
        this.$refs.childrenFull.get()
        await this.fetchPeople()
        await this.assignChildren()
        this.setChildOptionsData()
      }
    }
  }
};
</script>
